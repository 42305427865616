<template>
  <v-app :theme="themeName" class="application">
    <router-view v-slot="{ Component }">
      <Suspense>
        <component :is="Component" v-if="Component" />

        <Loading v-else />
      </Suspense>
    </router-view>
  </v-app>
</template>

<script setup lang="ts">
import Loading from '@/components/General/AppLoading.vue'
import useTheme from '@/composables/useTheme'

const { themeName } = useTheme()
</script>
