import type { NavItem } from '@/vue-router'

export const heItems: NavItem[] = [
  {
    text: 'Workstreams',
    icon: '$stream',
    name: 'home',
  },
]

export const siItems: NavItem[] = [
  {
    text: 'Workstreams',
    icon: '$stream',
    name: 'si',
  },
]
