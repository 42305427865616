<template>
  <v-app-bar class="justify-start" :floating="false" color="surface-strong">
    <v-app-bar-nav-icon @click="useNav().toggleMenu" />

    <v-app-bar-title class="mr-5 toolbar__title">
      {{ title }}
    </v-app-bar-title>

    <v-spacer />

    <!-- TODO: Implement search -->
    <!-- <ClaimSearch class="flex-grow-3" bg-color="surface-strong" /> -->

    <v-btn icon data-testid="user-menu-button">
      <v-icon icon="$account" />
      <v-menu
        activator="parent"
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <UserMenu />
      </v-menu>
    </v-btn>
  </v-app-bar>
</template>

<script setup lang="ts">
// import ClaimSearch from '@/components/General/ClaimSearch.vue'
import UserMenu from '@/components/User/UserMenu.vue'
import { useNav } from '@/composables/nav'

const title = import.meta.env.VITE_APP_TITLE
</script>

<style>
.toolbar__title {
  flex: 0 1 auto !important;
}
</style>
