import { ref } from 'vue'

const isMenuActive = ref(false)

export function useNav() {
  function toggleMenu() {
    isMenuActive.value = !isMenuActive.value
  }

  return {
    isMenuActive,
    toggleMenu,
  }
}
