import type { init } from '@/lib/flags'
import UserSession, { type Permission } from '@/lib/session'
import type { Session, SessionUser } from '@/types/Authentication'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSessionStore = defineStore('user', () => {
  const session = ref<UserSession<Session> | null>(null)
  const user = ref<SessionUser>({})
  let ld: ReturnType<typeof init>

  function decodeToken(value: string | null) {
    if (!value) {
      session.value = null
      user.value = {}
      return
    }

    session.value = new UserSession(value)
    user.value = {
      email: session.value.details.emails?.at(0),
      id: session.value.details.extension_SimplifiUserID,
      name: session.value.details.name,
    }
  }

  return {
    decodeToken,
    hasConfigAccess: computed(() => {
      return !!(
        session.value?.hasPermission('Operations.ConfigureShortCodes') ||
        session.value?.hasPermission('Operations.ConfigureWorkstreamTimers')
      )
    }),
    hasPermission(permission: Permission) {
      return !!session.value?.hasPermission(permission)
    },
    isLoaded: computed(() => !!user.value.id),
    ld,
    user,
  }
})
