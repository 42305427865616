<template>
  <v-card :elevation="16">
    <template #text>
      <div class="d-flex gap-4">
        <v-avatar icon="$account" :size="85" color="surface-variant" />

        <div class="d-flex flex-column justify-center">
          <p class="text-body-1">{{ user.name }}</p>
        </div>
      </div>
    </template>

    <v-divider />

    <v-card-actions>
      <ThemeToggle />
      <v-btn prepend-icon="$logout" @click="logOut">Log Out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { logOut } from '@/api/authentication'
import ThemeToggle from '@/components/General/ThemeToggle.vue'
import { useSessionStore } from '@/stores/session'

const user = useSessionStore().user
</script>
