<template>
  <template v-if="appAccess">
    <NavBar />

    <div class="main-app d-flex h-screen">
      <NavDrawer />

      <div class="w-100 overflow-y-auto">
        <router-view v-slot="{ Component }">
          <Transition :mode="'out-in'" :appear="true">
            <Suspense>
              <v-main class="layout pa-8 h-100">
                <component :is="Component" />
              </v-main>

              <template #fallback>
                <v-progress-circular />
              </template>
            </Suspense>
          </Transition>
        </router-view>
      </div>
    </div>
  </template>

  <AuthLayout v-else>
    <PermissionError />
  </AuthLayout>
</template>

<script setup lang="ts">
import NavBar from '@/components/NavBar.vue'
import NavDrawer from '@/components/NavDrawer.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import { flag, init } from '@/lib/flags'
import { useSessionStore } from '@/stores/session'
import PermissionError from '@/views/authentication/PermissionError.vue'
import { useLDClient } from 'launchdarkly-vue-client-sdk'

if (!useSessionStore().ld) {
  init()
}
await useLDClient().waitUntilReady()
const appAccess = flag.appAccess()
</script>

<style>
.layout {
  display: grid;
  grid-template-rows: auto 1fr;
}
</style>
