import type { Icons } from '@/plugins/vuetify.icons'
import { Theme } from '@/plugins/vuetify.themes'
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import type { LiteralUnion } from 'type-fest'
import { computed, readonly, ref } from 'vue'

const getTheme = () => {
  const storage = window.localStorage.getItem('theme')
  if (storage) {
    return storage as Theme
  }

  let theme = Theme.Light
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme = Theme.Dark
  }

  window.localStorage.setItem('theme', theme)
  return theme
}

// Variables declared outside of the function are shared between all instances
// of the function
// Shows how to use composables to share state between components
const theme = ref(getTheme())

export function init() {
  theme.value = getTheme()
}

type Icon = {
  name: LiteralUnion<Icons, string>
  color: string
}

const icons: Record<Theme, Icon> = {
  [Theme.Light]: {
    name: mdiWeatherSunny,
    color: '#ff9742',
  },
  [Theme.Dark]: {
    name: mdiWeatherNight,
    color: '#ffddaa',
  },
}

export default function useTheme() {
  return {
    // Use readonly to prevent accidental mutations
    themeName: readonly(theme),
    themeIcon: computed(() => icons[theme.value]),
    themeIcons: icons,
    toggleTheme() {
      theme.value = theme.value === Theme.Light ? Theme.Dark : Theme.Light
      window.localStorage.setItem('theme', theme.value)
    },
  }
}
