import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import SILayout from '@/layouts/SILayout.vue'
import { useSessionStore } from '@/stores/session'
import { registerGuard } from './router-guard'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/HomeView.vue'),
        },
        {
          path: 'complaints',
          name: 'complaints',
          component: () => import('@/views/ComplaintsView.vue'),
        },
        {
          path: 'config',
          name: 'config',
          component: () => import('@/layouts/ConfigLayout.vue'),
          beforeEnter: () => {
            if (!useSessionStore().hasConfigAccess) {
              return { name: 'permissionError' }
            }
          },
          children: [
            {
              path: '',
              name: 'timers',
              component: () => import('@/views/Config/TimersView.vue'),
              beforeEnter: () => {
                if (
                  !useSessionStore().hasPermission(
                    'Operations.ConfigureWorkstreamTimers',
                  )
                ) {
                  return { name: 'permissionError' }
                }
              },
            },
            {
              path: 'short-codes',
              name: 'shortCodes',
              component: () => import('@/views/Config/ShortCodesView.vue'),
              beforeEnter: () => {
                if (
                  !useSessionStore().hasPermission(
                    'Operations.ConfigureShortCodes',
                  )
                ) {
                  return { name: 'permissionError' }
                }
              },
            },
            {
              path: 'data',
              name: 'data',
              component: () => import('@/views/Config/DataView.vue'),
            },
          ],
        },
        {
          path: 'stats',
          name: 'stats',
          component: () => import('@/views/StatsView.vue'),
          beforeEnter: () => {
            if (
              !useSessionStore().hasPermission(
                'Operations.WorkstreamStatistics',
              )
            ) {
              return { name: 'permissionError' }
            }
          },
        },
      ],
    },

    {
      path: '/si',
      component: SILayout,
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: '',
          name: 'si',
          component: () => import('@/views/SIView.vue'),
        },
      ],
    },

    {
      path: '/identity/callback',
      component: AuthLayout,
      meta: {
        requiresAuth: true,
      },

      children: [
        {
          path: '',
          name: 'identityCallback',
          component: () =>
            import('@/views/authentication/IdentityCallback.vue'),
        },
      ],
    },

    {
      path: '/login/error',
      name: 'loginError',
      component: () => import('@/views/authentication/LoginError.vue'),
    },
    {
      path: '/loginError',
      redirect: { name: 'loginError' },
    },
    {
      path: '/permission/error',
      name: 'permissionError',
      component: () => import('@/views/authentication/PermissionError.vue'),
      meta: {
        title: 'Permission Error',
      },
    },
  ],
})

registerGuard(router)

router.beforeEach((to, from, next) => {
  document.title = [
    to.meta.title,
    `${import.meta.env.VITE_APP_TITLE} - Operations`,
  ]
    .filter((t) => !!t)
    .join(' | ')

  next()
})

export default router
