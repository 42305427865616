import {
  mdiAccount,
  mdiAccountGroup,
  mdiAccountReactivate,
  mdiAccountTie,
  mdiAlertCircleOutline,
  mdiCalendarRange,
  mdiCancel,
  mdiChartLine,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockOutline,
  mdiClose,
  mdiContentSave,
  mdiFormatListText,
  mdiHeadset,
  mdiHeartPlus,
  mdiHistory,
  mdiHome,
  mdiLogout,
  mdiMagnify,
  mdiMapMarker,
  mdiNoteEdit,
  mdiPalette,
  mdiPhone,
  mdiShieldAlert,
  mdiTagText,
  mdiWrench,
} from '@mdi/js'
import type { IconSet } from 'vuetify'
import { mdi, aliases as mdiAliases } from 'vuetify/iconsets/mdi-svg'

// Any new icons should be imported and added to the aliases object
const aliases = {
  ...mdiAliases,
  logout: mdiLogout,
  account: mdiAccount,
  search: mdiMagnify,
  history: mdiHistory,
  up: mdiChevronUp,
  down: mdiChevronDown,
  timer: mdiClockOutline,
  tick: mdiCheck,
  cross: mdiClose,
  tag: mdiTagText,
  save: mdiContentSave,

  // Nav
  home: mdiHome,
  profile: mdiAccountTie,
  policies: mdiNoteEdit,
  portals: mdiPalette,
  phone: mdiPhone,
  users: mdiAccountGroup,
  graph: mdiChartLine,

  // Job
  trade: mdiWrench,
  mapMarker: mdiMapMarker,
  calendar: mdiCalendarRange,
  abandoned: mdiCancel,
  returnVisit: mdiAccountReactivate,
  emergency: mdiAlertCircleOutline,
  stream: mdiFormatListText,

  // Customer
  vulnerability: mdiShieldAlert,
  healthAndSafety: mdiHeartPlus,
  complaints: mdiHeadset,
} as const

// Union string literal type Icons from aliases object keys and add $ prefix
// These are the actual icon names that can be used in the icon prop
export type Icons = `$${keyof typeof aliases}`

export default {
  aliases,
  sets: {
    mdi: mdi as IconSet,
  },
  defaultSet: 'mdi',
}
