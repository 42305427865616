// Vuetify
import { createVuetify } from 'vuetify'
import icons from './vuetify.icons'
import { Theme, themes } from './vuetify.themes'

export default createVuetify({
  icons,
  theme: {
    defaultTheme: Theme.Light,
    themes,
  },

  defaults: {
    VAutocomplete: {
      density: 'comfortable',
      color: 'secondary',
    },
    VBtn: {
      flat: true,
      variant: 'tonal',
    },
    VDialog: {
      persistent: true,
      maxWidth: '50rem',
    },
    VMenu: {
      transition: { duration: 150 },
    },
    VProgressCircular: {
      color: 'secondary',
      indeterminate: true,
    },
    VSelect: {
      density: 'comfortable',
      color: 'secondary',
    },
    VSwitch: {
      color: 'primary',
      inset: true,
    },
    VTooltip: {
      activator: 'parent',
      location: 'top',
      openDelay: '200',
      contentClass: 'shadow',
    },
  },
})
