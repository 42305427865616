<template>
  <v-navigation-drawer
    v-model="isMenuActive"
    class="nav-drawer text-left"
    bottom
    temporary
    width="300"
  >
    <v-list>
      <v-tabs v-model="tab" color="secondary">
        <v-tab value="HE">HE</v-tab>
        <v-tab v-if="showSI" value="SI">SI</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="HE">
          <template v-for="item in itemsForHE" :key="item.name">
            <v-list-item
              v-if="!item.children"
              :prepend-icon="item.icon"
              :title="item.text"
              :to="{ name: item.name }"
              :exact="true"
            />

            <v-list-group v-else>
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="item.icon"
                  :title="item.text"
                />
              </template>

              <v-list-item
                v-for="child in item.children"
                :key="child.name"
                :value="child.name"
                :title="child.text"
                :prepend-icon="child.icon"
                :to="{ name: child.name }"
                :exact="true"
              />
            </v-list-group>
          </template>

          <v-list-item
            v-if="useSessionStore().hasConfigAccess"
            prepend-icon="$edit"
            to="/config"
          >
            Config
          </v-list-item>

          <v-list-item
            v-if="
              useSessionStore().hasPermission('Operations.WorkstreamStatistics')
            "
            prepend-icon="$graph"
            :to="{ name: 'stats' }"
          >
            Stats
          </v-list-item>
        </v-tabs-window-item>

        <v-tabs-window-item value="SI">
          <template v-for="item in itemsForSI" :key="item.name">
            <v-list-item
              :prepend-icon="item.icon"
              :title="item.text"
              :to="{ name: item.name }"
              :exact="true"
            />
          </template>
        </v-tabs-window-item>
      </v-tabs-window>

      <v-divider />

      <v-list-item prepend-icon="$complaints" :to="{ name: 'complaints' }">
        Complaints
      </v-list-item>

      <v-spacer />

      <v-divider />

      <v-list-item prepend-icon="$logout" @click="logOut">Log Out</v-list-item>
      <ThemeToggle />
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { logOut } from '@/api/authentication'
import ThemeToggle from '@/components/General/ThemeToggle.vue'
import { useNav } from '@/composables/nav'
import { heItems, siItems } from '@/router/navigation-drawer'
import { useSessionStore } from '@/stores/session'
import { ref } from 'vue'
import { flag } from '@/lib/flags'

const itemsForHE = ref(heItems)
const itemsForSI = ref(siItems)
const { isMenuActive } = useNav()
const tab = ref('HE')
const showSI = flag.siDashboard()
</script>

<style scoped>
/* Style list as flex so we can push things to the bottom easily */
/* Also allow overflow-y so if the menu gets large / used on mobile we can scroll it */
.v-list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-y: auto !important;
  padding: 0;
}
/* Prevent menu items being visually selectable */
.v-list-item-title {
  user-select: none;
}
</style>
