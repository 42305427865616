// Styles
import 'vuetify/styles'
import 'vue3-easy-data-table/dist/style.css'

// Fonts
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/700.css'

// Framework
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import vuetify from '@/plugins/vuetify'
import { vSanitize } from '@/directives/sanitize'

import App from './App.vue'
import router from './router'
import { configureApiService } from '@/api/api'
import { initializeAuthentication } from '@/api/authentication'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { initLogging, addFeatureFlagEvaluation } from '@simplifi/logging'

// Import our CSS last so they get loaded after Vuetify's
import './assets/main.css'
import './assets/helpers.css'

initLogging({
  datadogApplicationId: import.meta.env.VITE_APP_DD_APPLICATION_ID,
  datadogClientToken: import.meta.env.VITE_APP_DD_CLIENT_TOKEN,
  environmentName: import.meta.env.VITE_APP_DD_ENVIRONMENT,
  serviceName: import.meta.env.VITE_APP_DD_SERVICE,
  version: import.meta.env.VITE_APP_DD_CI_BUILD_ID,
  commitSha: import.meta.env.VITE_APP_DD_GIT_COMMIT_SHA,
  repositoryUrl: import.meta.env.VITE_APP_DD_GIT_REPO_URL,
  allowedUrls: [import.meta.env.VITE_APP_API_BASE_URI],
})

const app = createApp(App)

app.use(createPinia())

configureApiService()
await initializeAuthentication()

app.use(router)
app.use(vuetify)
app.directive('sanitize', vSanitize)

app.use(LDPlugin, {
  clientSideID: import.meta.env.VITE_APP_LD_CLIENT_ID,
  deferInitialization: true,
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: any) => {
          addFeatureFlagEvaluation(key, detail.value)
        },
      },
    ],
  },
})

app.mount('#app')
