import { decode } from '@/lib/jwt'

type Session = {
  extension_Permissions: string
  extension_Role: string
}

export interface Permissions {}
export type Permission = keyof Permissions

export interface Roles {}
export type Role = keyof Roles

export default class UserSession<T> {
  details
  permissions
  roles

  constructor(token: string) {
    this.details = decode<T & Session>(token)
    this.permissions = (this.details.extension_Permissions
      ?.split(',')
      .filter(Boolean) ?? []) as Permission[]
    this.roles = (this.details.extension_Role?.split(',').filter(Boolean) ??
      []) as Role[]
  }

  hasPermission(permission: Permission) {
    return !!this.permissions.includes(permission)
  }

  hasRole(role: Role) {
    return !!this.roles.includes(role)
  }
}
