import DOMPurify from 'dompurify'
import type { Directive } from 'vue'

function sanitizeHTML(html: string) {
  return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
}

export const vSanitize: Directive = {
  created(el, { value }) {
    el.innerHTML = sanitizeHTML(value)
  },

  updated(el, { value }) {
    el.innerHTML = sanitizeHTML(value)
  },
}
