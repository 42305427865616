import type { ThemeDefinition } from 'vuetify'

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#eee',
    'on-background': '#2a373e',
    surface: '#fafafa',
    'on-surface': '#2a373e',
    'surface-variant': '#3c4d56',
    'on-surface-variant': '#d0dfe7',
    'surface-strong': '#607d8c',
    'on-surface-strong': '#fafafa',
    'surface-darken-1': '#d0dfe7',
    'on-surface-darken-1': '#3c4d56',
    primary: '#607d8c',
    secondary: '#009dff',
    'secondary-on-surface': '#00ffa1',
    active: '#111111',
    error: '#e6203a',

    // Job Statuses
    Pending: '#e6203a',
    Accepted: '#2196f3',
    AutoDeploying: '#2196f3',
    Unavailable: '#e6203a',
    InProgress: '#ff9800',
    Cancelled: '#e6203a',
    Completed: '#4caf50',

    // Vulnerability / Health Levels
    Low: '#69a1be',
    'Low-variant': '#d0dfe7',
    Medium: '#ff9800',
    'Medium-variant': '#ffebcc',
    High: '#e6203a',
    'High-variant': '#fccfcf',
  },
}

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#475b65',
    'on-background': '#fafafa',
    surface: '#607d8c',
    'on-surface': '#fafafa',
    'surface-variant': '#d0dfe7',
    'on-surface-variant': '#3c4d56',
    'surface-strong': '#607d8c',
    'on-surface-strong': '#fafafa',
    'surface-darken-1': '#3c4d56',
    'on-surface-darken-1': '#d0dfe7',
    primary: '#fafafa',
    secondary: '#00ffa1',
    'secondary-on-surface': '#00ffa1',
    active: '#111111',
    error: '#e6203a',

    // Job Statuses
    Pending: '#e6203a',
    Accepted: '#2196f3',
    AutoDeploying: '#2196f3',
    Unavailable: '#e6203a',
    InProgress: '#ff9800',
    Cancelled: '#e6203a',
    Completed: '#4caf50',

    // Vulnerability / Health Levels
    Low: '#69a1be',
    'Low-variant': '#69a1be',
    Medium: '#ff9800',
    'Medium-variant': '#ff9800',
    High: '#e6203a',
    'High-variant': '#e6203a',
  },
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export const themes: Record<Theme, ThemeDefinition> = {
  [Theme.Light]: light,
  [Theme.Dark]: dark,
}
